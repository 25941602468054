import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeading from '../components/PageHeading';
import * as styles from './collection.module.scss';
export const query = graphql`
  query ContributingQuery($path: String) {
    sitePage: allSitePage(filter: { path: { eq: $path } }) {
      nodes {
        component
        path
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};

const MDXLayout = ({
  children,
  data,
  pageContext
}) => <Layout fullWidth editLink={pageContext.fileRelativePath}>
    <SEO title="Contributing to a New Relic Open Source Project" />
    <PageHeading title="Contributing to a New Relic Open Source Project" subheader="Demonstrating a standard fork-and-pull-request code contribution workflow" />
    <div className="primary-content">
      <main>{children}</main>
    </div>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className={styles.featuredVideoContainer}>
  <div className="responsive-video">
    <iframe width="1000" height="562.704471" src="https://fast.wistia.net/embed/iframe/ypgdkgysmo?videoFoam=true" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; showinfo; modestbranding" placeholder="" />
  </div>
    </div>
    <p>{`For those newer to open source contribution at New Relic, the following points encapsulate the major steps and issues. For command line description of this workflow, `}<a parentName="p" {...{
        "href": "https://gist.github.com/tangollama/5d9d63fef0ed4498fb5fbb057bb5983d",
        "target": "__blank",
        "rel": "noopener noreferrer"
      }}><strong parentName="a">{`consult this documentation`}</strong></a>{`.`}</p>
    <h2>{`Step 1: Fork a Repository`}</h2>
    <p>{`Instead of requiring write access to the repository, a `}<a parentName="p" {...{
        "href": "https://help.github.com/en/enterprise/2.13/user/articles/fork-a-repo",
        "target": "__blank",
        "rel": "noopener noreferrer"
      }}><strong parentName="a">{`fork`}</strong></a>{` allows you the ability to modify code and contribute changes via a `}<a parentName="p" {...{
        "href": "https://help.github.com/en/github/collaborating-with-issues-and-pull-requests/about-pull-requests",
        "target": "__blank",
        "rel": "noopener noreferrer"
      }}><strong parentName="a">{`pull request`}</strong></a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/fork_repository.png",
        "alt": "fork",
        "title": "Click the fork button in GitHub"
      }}></img></p>
    <p>{`Navigate to the repository in GitHub and click the `}<strong parentName="p">{`Fork`}</strong>{` button.`}</p>
    <h2>{`Step 2: Implement changes on your Fork`}</h2>
    <p>{`Now that you have a copy of the repository, implement changes against your forked copy of the code but executing the `}<inlineCode parentName="p">{`clone`}</inlineCode>{` command, and modify your copy of the code.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/your_fork.png",
        "alt": "your fork",
        "title": "Your fork on GitHub"
      }}></img></p>
    <h3>{`Commit message guidance`}</h3>
    <p>{`As you `}<inlineCode parentName="p">{`commit`}</inlineCode>{` changes to your `}<strong parentName="p">{`fork`}</strong>{`, you want to ensure that your commit messages are human-readable, relevant, descriptive, and follow a standard methodology so that tooling can automate the creation of `}<strong parentName="p">{`changelogs`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/commit_message.png",
        "alt": "conventional commit messages",
        "title": "Commit messages following Conventional Commit standards"
      }}></img></p>
    <p>{`To support that objective, New Relic open source projects seek to follow the `}<a parentName="p" {...{
        "href": "https://www.conventionalcommits.org/en/v1.0.0-beta.2/#specification",
        "target": "__blank",
        "rel": "noopener noreferrer"
      }}>{`Conventional Commit specification`}</a>{` for `}<inlineCode parentName="p">{`git`}</inlineCode>{` commit messages.`}</p>
    <h4>{`Please ensure`}</h4>
    <ol>
      <li parentName="ol">{`That your commit messages follow the `}<a parentName="li" {...{
          "href": "https://www.conventionalcommits.org/en/v1.0.0-beta.2/#specification",
          "target": "__blank",
          "rel": "noopener noreferrer"
        }}>{`Convention Commit specification`}</a>{`.`}</li>
      <li parentName="ol">{`That your commits tie back to a specific `}<a parentName="li" {...{
          "href": "https://help.github.com/en/github/managing-your-work-on-github/about-issues",
          "target": "__blank",
          "rel": "noopener noreferrer"
        }}><strong parentName="a">{`Issue`}</strong></a>{` in the upstream repository (`}<em parentName="li">{`hint: if there isn't an issue for your change, add it yourself :)`}</em>{` ).`}</li>
    </ol>
    <h2>{`Step 3: Initiate a Pull Request`}</h2>
    <p>{`Once you've committed your changes to your fork, you can now issue a `}<a parentName="p" {...{
        "href": "https://help.github.com/en/github/collaborating-with-issues-and-pull-requests/about-pull-requests",
        "target": "__blank",
        "rel": "noopener noreferrer"
      }}><strong parentName="a">{`Pull Request`}</strong></a>{` back to the New Relic project.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/pull_request.png",
        "alt": "create a pull request",
        "title": "create a pull request"
      }}></img></p>
    <h2>{`Step 4: Accept the New Relic CLA (Contributor License Agreement)`}</h2>
    <p>{`Finally, if you've not contributed to a New Relic open source project before, we're going to prompt you to electronically sign our `}<a parentName="p" {...{
        "href": "/cla"
      }}><strong parentName="a">{`Contributor License Agreement (CLA)`}</strong></a>{`, highlighting that you're freely providing code for the benefit of the open source project and community.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/cla.png",
        "alt": "cla",
        "title": "cla"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      